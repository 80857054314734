




































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { UserResponseListItem } from '@/models/users';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';

@Component({
  components: { EmptyPlaceholder },
})
export default class UserTable extends Vue {
  @Prop({ required: true })
  data!: UserResponseListItem[];

  get users() {
    return this.data;
  }

  @Emit(`navigateEdit`)
  navigateToEditUsers(data: UserResponseListItem) {
    return data;
  }

  @Emit(`navigateView`)
  navigateToViewUsers(data: UserResponseListItem) {
    return data;
  }

  @Emit('delete')
  deleteUser(data: UserResponseListItem) {
    return Number(data.id);
  }
}
