
















































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import StudentTeacherSearchBar from '@/components/common/StudentTeacherSearchBar.vue';
import UserTable from '@/components/users/UserTable.vue';
import { UserListFetchParams, UserResponseListItem } from '@/models/users';
import { UserStores } from '@/store/users';
import PaginationControls from '@/components/common/PaginationControls.vue';
import { PaginationDetails } from '@/models/generic';
@Component({
  components: { Page, StudentTeacherSearchBar, UserTable, PaginationControls },
})
export default class Users extends Vue {
  userCreateStore = UserStores.create;
  userListStore = UserStores.list;
  userDetailStore = UserStores.detail;
  userStateStore = UserStores.state;
  // Pagination Details
  keyword = ``;
  sortby = ``;
  sortorder = ``;
  filter = ``;
  totalCount = 0;
  currentPage = 1;
  currentLimit = 10;
  isPaginationReset = false;
  isPageSpecified = false;
  currentUsers: UserResponseListItem[] = [];

  get combinedSort() {
    return this.sortorder.length > 0 && this.sortby.length > 0 ? `${this.sortby}-${this.sortorder}` : '';
  }

  get users() {
    return this.currentUsers;
  }

  mounted() {
    if (this.userStateStore.isModified === false) {
      // If nothing is modified, set back to previous state
      this.currentUsers = this.userStateStore.usersData;
      this.totalCount = this.userStateStore.currentTotalCountState as number;
      this.currentPage = this.userStateStore.currentPageState as number;
      this.currentLimit = this.userStateStore.currentLimitState as number;
      this.keyword = this.userStateStore.currentKeywordState as string;
      this.sortby = this.userStateStore.currentSortByState as string;
      this.sortorder = this.userStateStore.currentSortOrderState as string;
      this.filter = this.userStateStore.currentFilterState as string;
      this.isPageSpecified = true;
    } else {
      this.userStateStore.setIsModified(null);
      this.retrieveUsers();
    }
  }

  beforeDestroy() {
    this.saveCurrentState();
  }

  retrieveUsers() {
    const params: Partial<UserListFetchParams> = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    if (this.keyword.length > 0 ) {
      params.keyword = this.keyword;
    }

    if (this.sortby.length > 0) {
      params.sortby = this.sortby;
    }

    if (this.sortorder.length > 0) {
      params.sortorder = this.sortorder;
    }

    if (this.filter.length > 0) {
      params.filter = this.filter;
    }
    this.userListStore.retrieveUsers(params);
  }

  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.currentLimit = payload.limit;
    this.retrieveUsers();
  }

  resetPaginationStatus(payload: boolean) {
    this.isPaginationReset = payload;
  }

  resetCurrentPageStatus(payload: boolean) {
    this.isPageSpecified = payload;
  }

  @Watch(`userListStore.response`)
  setUsers() {
    if (this.userListStore.response) {
      this.currentUsers = [];
      this.totalCount = this.userListStore.response.totalCount;
      this.userListStore.response.objects.forEach((item) => {
        this.currentUsers.push({
          id: item.id,
          name: item.name,
          email: item.email,
          mobile: item.mobile,
          birthday: item.birthday,
          lastlogin: item.lastlogin,
          role: item.role,
          loginsource: item.loginsource,
        });
      });
      this.saveCurrentState();
    }
  }

  redirectToCreateUsers() {
    this.saveCurrentState();
    this.$router.push({
      path: `users/create`,
    });
  }

  redirectToEditUsers(data: UserResponseListItem) {
    this.saveCurrentState();
    this.$router.push({
      path: `users/${data.id}/edit`,
    });
  }

  redirectToViewUsers(data: UserResponseListItem) {
    this.saveCurrentState();
    this.$router.push({
      path: `users/${data.id}/view`,
    });
  }

  deleteUser(id: number) {
    this.userDetailStore.deleteUserById(id);
  }

  @Watch(`userDetailStore.response`)
  updateUserList() {
    if (this.userDetailStore.response) {
      this.retrieveUsers();
    }
  }

  handleSearch(searchKeyword: string) {
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.keyword = searchKeyword;
    this.retrieveUsers();
  }

  handleSorting(userSortby: string, userSortOrder: string) {
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.sortby = userSortby;
    this.sortorder = userSortOrder;
    this.retrieveUsers();
  }

  handleFilter(userFilter: string) {
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.filter = userFilter;
    this.retrieveUsers();
  }

  saveCurrentState() {
    this.userStateStore.updateUsersData(this.currentUsers);
    this.userStateStore.updateUserPageState(this.currentPage);
    this.userStateStore.updateUserLimitState(this.currentLimit);
    this.userStateStore.updateUserKeywordState(this.keyword);
    this.userStateStore.updateUserTotalState(this.totalCount);
    this.userStateStore.updateUserSortByState(this.sortby);
    this.userStateStore.updateUserSortOrderState(this.sortorder);
    this.userStateStore.updateUserFilterState(this.filter);
  }
}
